/*==========================
02. Color CSS
============================*/
// Background Colors
.bg {

  &-white {
    background-color: $white;
  }

  &-offwhite {
    background-color: $offWhite;
  }

  &-brand {
    background-color: $brandColor;
  }

  &-blackSoft {
    background-color: $blackSoft;
  }
}

.gradien-bg {
  background: linear-gradient(90deg, #1D1D20 0%, #343A40 100%);
}

// Text Colors
.text {
  &-brand {
    color: $brandColor;
  }
}