/*===========================
--. Footer Style CSS
===========================*/
.footer-area {
  background-color: #303030;
  color: #9a9a9a;

  .container {
    .wrap {
      border-top: 1px solid rgba(136,136,136,.2);
      padding: 30px 0 25px;

      p {
        font-size: 13px;
        color: #9a9a9a;
        width: 100%;
        margin: 10px auto;
        display: block;
        line-height: 1.7;
      }

      a {
        text-decoration: underline;
        font-size: 13px;
        color: #9a9a9a;
      }

      a:hover {
        color: $white;
      }

      .left {
        float: left;

        @media #{$sm-device} {
          width: 100%;
        }
      }

      .right {
        float: right;

        @media #{$sm-device} {
          width: 100%;
        }
      }

      .clear {
        content: '';
        display: table;
        clear: both;
      }
    }
  }

  .contact-info {
    padding: 25px 0 50px 0;
  }

  h6 {
    margin: 0 0 30px;
    font-size: 16px;
    color: $white;
    font-weight: 700;
  }

  .logo img {
    max-width: 300px;
    height: auto;
    width: 100%;
    margin-left: -16px;
  }

  ul {
    li {
      font-size: 14px;
      padding: 5px 0;

      a {
        color: #9a9a9a;

        &:hover {
          color: #fff;
        }

        .selected {
          color: #fff;
        }

        &:active {
          color: #fff;
        }
      }
    }
  }

  &-light {
    background-color: #fff !important;
    color: #000;

    h6 {
      color: #000;
    }

    .container {
      .wrap {
        p {
          color: #595959;
        }

        a {
          color: #595959;
    
          &:hover {
            color: #f12635;
          }
        }
      }
    }

    ul {
      li {
        color: #595959;
        a {
          color: #595959;

          &:hover {
            color: #f12635;
          }
        }
      }
    }
  }

  &-contact {
    margin-top: -100px;
    padding-top: 100px;
  }
}

.call-top-action-wrap {
  background-color: $offWhite;
}

.widget-item {
  .widget-title {
    color: $brandColor;
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    margin-top: -1px;
    margin-bottom: 22px;

    @media #{$sm-device} {
      margin-bottom: 15px;
    }
  }

  address {
    line-height: 2;
    margin-bottom: 0;
    font-weight: 500;
  }
}

.widget-list {
  li {
    line-height: 2;

    a {
      color: $paragraph;
      font-weight: 500;
      transition: 0.2s;

      &:hover {
        color: $brandColor;
        padding-left: 5px;
      }
    }
  }
}

// About Widget
.about-widget {
  img {
    max-width: 120px;
    margin-bottom: 20px;
  }
}

.copyright-txt {
  margin-top: 25px;
  font-weight: 500;

  @media #{$sm-device} {
    margin-top: 15px;
  }
}