/*============================
03. Common Style CSS
==============================*/
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
}

section {
  padding-left: 15px;
  padding-right: 15px;
  // margin-left: -15px;
  // margin-right: -15px;

  @media #{$md-device} {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  h3:first-child {
    color: #343a40;
    font-size: 46px;
    line-height: 69px;
    font-weight: 900;

    @media #{$sm-device} {
      font-size: 32px;
      line-height: 50px;
    }

    @media #{$xs-device} {
      font-size: 26px;
      line-height: 40px;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

.col {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-1__5 {
  @extend .col;

  @media #{$lgmax-device} {
      flex: 0 0 20%;
      max-width: 20%;
  }
}

.col-xl-1__5 {
  @extend .col;

  @media #{$xl-device} {
      flex: 0 0 20%;
      max-width: 20%;
  }
}

.large-device {
  @media #{$sm-device} {
    display: none;
  }
}

.small-device {
  display: none;

  @media #{$sm-device} {
    display: flex;
  }
}

/*-----------------
Overflow
-------------------*/
.fix {
  overflow: hidden;

  &-x {
    overflow-x: hidden;
  }

  &-y {
    overflow-y: hidden;
  }
}

/*-----------------
Section Title
-------------------*/
.section-title {
  margin-bottom: 55px;

  @media #{$sm-device} {
    margin-bottom: 40px;
  }

  h6 {
    margin-bottom: 13px;
    font-weight: 400;
    text-transform: uppercase;
  }

  h2 {
    @media #{$sm-device} {
      font-size: 24px;
    }
  }

  &--light {
    color: #bbbbbb;

    h2, h6 {
      color: $white;
    }
  }
}

/*----------------------
Background Image
-----------------------*/
.bg-img {
  background: no-repeat center center;
  background-size: cover;
}


/*----------------------
Container
-----------------------*/
.container {
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }

  @media screen and (min-width: 1920px) {
    max-width: 1326px;
    width: 80%;
  }

  &-fluid {
    padding: 0 100px;

    @media #{$lg-device} {
      padding: 0 15px;
    }

    @media #{$md-device, $sm-device} {
      padding: 0 30px;
    }

    @media #{$xxs-device} {
      padding: 0 15px;
    }
  }
}


/*----------------------
Button Style
-----------------------*/
.btn {
  transition: 0.4s;

  &:hover {
    transition: 0.4s;
    color: $white;
    transform: translateY(-5px);
  }

  &-brand {
    background-color: $brandColor;
    border-radius: 100px;
    color: $white;
    padding: 16px 40px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;

    &:hover {
      color: $white;
    }

    @media #{$sm-device} {
      padding: 13px 25px;
    }
  }

  &-square {
    border-radius: 0;
  }

  &-solid {
    border-radius: 100px;
    color: $white;
    padding: 16px 40px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;

    @media #{$sm-device} {
      padding: 13px 25px;
    }
  }

  &-primary {
    color: #fff !important;
    background-color: $primary;
    background-image: $gradient-primary !important;
    border: none;

    &:hover {
      background-color: #ec4941 !important;
    }
  }

  &-red {
    background-color: $red;

    &:hover {
      color: $red;
      background-color: $white;
      border: 2px solid $red;
    }
  }

  &-hard-red {
    color: #fff !important;
    background-color: $primary;

    &:hover {
      background-color: #ec4941 !important;
    }
  }

  &-white {
    background-color: $white;
    border-radius: 100px;
    color: $brandColor;
    padding: 16px 40px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;

    &:hover {
      color: $brandColor;
      background-color: #ffffff8a;
      // border: 2px solid $brandColor;
    }

    @media #{$sm-device} {
      padding: 13px 25px;
    }
  }

  &-light {
    background-color: transparent;
    color: $white !important;
    border: 1px solid $white !important;

    &:hover {
      background-color: transparent;
      color: $red;
      border: 2px solid $red;
    }
  }

  &-sm {
    padding: 12px 20px !important;
    font-size: 14px !important;
  }

  &-outline {
    border: 2px solid $brandColor;
    color: $paragraph;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    padding: 16px 40px;
    border-radius: 100px;
    @media #{$md-device} {
      padding: 14px 30px;
      font-size: 16px;
    }

    @media #{$sm-device} {
      padding: 12px 20px;
      font-size: 15px;
    }

    &:hover {
      background-color: $brandColor;
      color: $white;
    }

    &-white {
      border: 2px solid $white;
      background-color: transparent;
      color: $white;

      &:hover {
        border: 2px solid $red;
        background-color: $red;
      }
    }

    &-red {
      border: 2px solid $red;
      background-color: transparent;
      color: $red;

      &:hover {
        border: 2px solid $red;
        background-color: $red;
      }
    }
  }
}

/*----------------------
Slick Index
-----------------------*/
.slick-list {
  .slick-slide {
    border: 0 solid transparent !important;

    & > div {
      & > div {
        vertical-align: middle;
        outline: none;
      }
    }
  }
}

.slick-dots {
  display: flex !important;
  margin-top: 35px;
  justify-content: center;
  height: 12px;

  li {
    margin-right: 10px;

    button {
      border: 2px solid #c4c4c4;
      background-color: #c4c4c4;
      border-radius: 50%;
      text-indent: -100000px;
      height: 12px;
      width: 12px;
    }

    &.slick-active {
      button {
        background-color: #f12535;
        border: 2px solid #f12535;
      }
    }
  }

  &--light {
    li {
      button {
        border-color: $white;
      }

      &.slick-active {
        button {
          background-color: $white;
        }
      }
    }
  }

  &--red {
    li {
      button {
        border-color: #722727;
        background-color: #722727;
      }

      &.slick-active {
        button {
          border-color: #FC4F4F;
          background-color: #FC4F4F;
        }
      }
    }
  }
}

@for $i from 1 through 6 {
  .slick-row-#{5 * $i} {
    .slick-list {
      margin: 0 #{-2.5px * $i};

      .slick-slide {
        margin: 0 #{2.5px * $i};
      }
    }
  }
}


/*--------------------------------
Scroll Top Button
----------------------------------*/
.btn-scroll-top {
  background-color: $brandColor;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
  color: $white;
  cursor: pointer;
  @include font-size(25px);
  display: block;
  text-align: center;
  line-height: 60px;
  position: fixed;
  bottom: -60px;
  right: 30px;
  height: 60px;
  width: 60px;
  z-index: 999;
  @include hide;

  @media #{$sm-device} {
    line-height: 50px;
    height: 50px;
    width: 50px;
  }

  &:hover {
    background-color: $hvrColor;
  }

  &.show {
    bottom: 30px;
    @include show;
  }
}


/*--------------------------------
Blockquote Style
----------------------------------*/
blockquote, .blockquote {
  background-color: $offWhite;
  color: $paragraph;
  @include font-size(20px);
  line-height: 1.4;
  padding: 42px 44px 80px;
  position: relative;
  margin-bottom: 20px;

  @media #{$sm-device} {
    padding: 20px 22px 60px;
  }

  &:before {
    content: '\f10e';
    font-family: $fa-icon;
    position: absolute;
    right: 40px;
    bottom: 40px;

    @media #{$sm-device} {
      right: 30px;
      bottom: 20px;
    }
  }

  &-title {
    color: $brandColor;
    text-transform: uppercase;
    @include font-size(14px);
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 1;
  }
}

/*--------------------------------
Tag Style
----------------------------------*/
.tag-outline {
  border: 1px solid $red;
  border-radius: 4px;
  color: #595959;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 8px 16px;

  &.active {
    background-color: $red;
    color: $white;
  }
}

.tag-outline:hover {
  background-color: $red;
  color: $white;
}

.tag {
  background-color: $red;
  border-radius: 4px;
  color: $white;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  padding: 8px 16px;
}

/*--------------------------------
Animation Style
----------------------------------*/
:root {
  --animate-duration: 1.5s;
  --animate-delay: 1s;
}

/*--------------------------------
BreakNews Style
----------------------------------*/
.latest-post {
  margin-top: 10%;
}

.post-label {
  border-radius: 4px;
  color: $white;
  background-color: #ff0100;
  width: fit-content;
  font-size: 13px;
  padding: 4px 6px;
}

.post-item {
  display: flex;
  margin-top: 12px;

  .post-thumb {
    margin-right: 1rem;
    margin-top: 3px;
    width: 120px;

    @media #{$sm-device} {
      margin-top: 5px;
      margin-right: 0.5rem;
      width: 100px;
    }
  }

  .post-info {
    h6 {
      max-width: 400px;
      a {
        word-break: break-word;
        font-size: 14px;
        color: $white;

        @media #{$sm-device} {
          font-size: 12px;
        }
      }
    }
  }

  &:hover {
    .post-thumb {
      img {
        border: 1px solid #F12635;
      }
    }

    .post-info {
      h6 a {
        color: #F12635;
      }
    }
  }
}


.icon-box {
  display: block;
  transition: 0.4s;

  &:hover {
    transform: translateY(-10px);
  }

  .icon-wrap {
    margin: 0 0 5px;
    display: inline-block;
    text-align: center;
    line-height: 70px;
    vertical-align: middle;
    font-size: 50px;
    text-align: inherit;
    white-space: nowrap;

    img {
      height: 40px;
      width: auto;
    }
  }

  .content-wrap {
    h3 {
      text-transform: capitalize;
      color: #495057;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  &:hover {
    img {
      filter: grayscale(100%);
    }

    h3 {
      color: $red;
    }
  }
}

.col-inner {
  padding-left: 25px;
  padding-right: 25px;  
}

.image-box {
  .image-wrap {
    margin: 0 0 5px;
    display: inline-block;
    text-align: center;
    line-height: 70px;
    vertical-align: middle;
    font-size: 50px;
    text-align: inherit;
    white-space: nowrap;

    img {
      height: 200px;
      width: auto;
    }
  }

  .content-wrap {
    h3 {
      text-transform: capitalize;
      color: #495057;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
}

.partner-card {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0 0 8px 4px rgba(52,58,64,0.12);
  border-radius: 8px;
  height: 100%;
  position: relative;
  transition: 0.4s;

  .image-wrap {
    img {
      height: 60px;
    }
  }

  .content-wrap {
    margin-bottom: 4rem;
    // min-height: 180px;
    
    @media #{$lg-device} {
      min-height: 240px;
    }
    .title {
      line-height: 1.4;
      font-size: 26px;
      color: #404044;
      clear: both;
      font-weight: 700;
      margin: 35px 0 20px;
      position: relative;
    }

    .description {
      margin-bottom: 0 !important;
      margin: 12px 0;
      margin: 0;
      line-height: 1.5;
      letter-spacing: -.01em;
      font-size: 14px;
    }

    @media #{$xs-device} {
      // min-height: 320px;

      .title {
        font-size: 20px;
        line-height: 1.2;
      }
    }
  }

  .btn-wrap {
    position: absolute;
    bottom: 30px;

    a {
      color: $red;
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media #{$mdmin-device} {
    &:hover {
      transform: translateY(-10px);
    }
  }

  &--black:hover {
    background: linear-gradient(90deg, #1D1D20 0%, #343A40 100%);

    .title {
      color: #fff
    }

    .description {
      color: #fff
    }

    img {
      filter: brightness(0) invert(1);
    }
  }

  &--gray {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .6;
    vertical-align: middle;
    transition: all 0.4s ease-in-out;
  }

  &--gray:hover {
    border: 1px solid $red;
    box-shadow: 0 0 8px 4px rgba(255,170,170,0.2);
    -webkit-filter: unset;
    filter: unset;
    opacity: 1;
  }

  &--outline:hover {
    border: 1px solid $red;
    box-shadow: 0 0 8px 4px rgba(255,170,170,0.2);
    -webkit-filter: unset;
    filter: unset;
    opacity: 1;
  }
}

.contact-modal {
  width: 100%;
  height: 100%;
  max-width: unset;
  margin: auto;
  position: relative;

  .modal-content {
    @media #{$mdmin-device} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
    }

    @media #{$mdmax-device} {
      width: 85%;
      margin: auto;
    }
  
    @media only screen and (min-width: 1600px) {
      width: 60%;
      max-width: 1000px;
    }
  }

  .modal-body {
    padding: 0
  }
}

ul.order-list {
  list-style-type: decimal;

  > li {
    list-style: unset;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 10px;
  }
}

ul.bubble-list {
  list-style-type: disc;

  > li {
    list-style: unset;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 10px;
  }
}

ul.dashed-list {
  list-style-type: none;
  padding-left: 10px;

  > li::before {
    display: inline-block;
    content: "-";
    width: 2.5em;
    margin-left: -2.5em;
  }
}

.badge {
  font-size: 0.9375rem;
  padding: 5px 10px 7px 10px;
  border-radius: 20px;
  width: fit-content;
}

.card-col {
  padding-top: 15px;
  padding-bottom: 15px;
}

.call-to-action-container {
  z-index: 3;
  width: 95%;
  position: relative;

  .bg-container {
    position: absolute;
    bottom: 0;
    z-index: -1;
  }

  .content-container {
    display: flex;

    .image-group {
      margin-top: -50px;

      img {
        height: 450px;
      }
    }
  
    .text-group {
      margin: auto;
      text-align: center;

      h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 30px;
      }

      .btn {
        border: 1px solid #fff;
      }

      .btn-solid {
        border-radius: 10px;
      }

      .btn-hard-red {
        color: #fff !important;
        background-color: transparent;

        &:hover {
          color: $primary !important;
          background-color: #fff;
        }
      }
    }
  }

  @media screen and (min-width: 1921px) {
    margin: auto;
  }

  @media only screen and (min-width: 1600px) {
    background-size: cover;
    max-width: 1920px;
    .bg-container {
      display: none;
    }

    .content-container {
      .image-group {
        img {
          height: 500px;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    width: 100%;

    .content-container {
      .image-group {
        img {
          height: 300px;
        }
      }

      .text-group {
        h3 {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;

    .bg-container{
      display: none;
    }

    

    .content-container {
      display: block;

      .image-group {
        text-align: center;
        margin-top: -100px;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    h3 {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.react-simple-image-viewer__modal {
  z-index: 100 !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.react-simple-image-viewer__slide {
  img {
    min-width: 380px;
  }
}

.fade {
  opacity: 1 !important;
}

.btn-icon {
  align-items: center;
  justify-content: center;
  border: 0;
}