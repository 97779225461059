@font-face {
    font-family: "Muli";
    src: url('../../fonts/Muli-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Muli";
    src: url('../../fonts/Muli-Light.ttf') format('truetype');
    font-weight: $font-weight-light;
}

@font-face {
    font-family: "Muli";
    src: url('../../fonts/Muli-SemiBold.ttf') format('truetype');
    font-weight: $font-weight-semibold;
}

@font-face {
    font-family: "Muli";
    src: url('../../fonts/Muli-Bold.ttf') format('truetype');
    font-weight: $font-weight-bold;
}

@font-face {
    font-family: "Muli";
    src: url('../../fonts/Muli-ExtraBold.ttf') format('truetype');
    font-weight: $font-weight-extrabold;
}

@font-face {
    font-family: "Muli";
    src: url('../../fonts/Muli-Black.ttf') format('truetype');
    font-weight: $font-weight-black;
}

