/*==========================
04. Index Area Style CSS
===========================*/

.header-area{
  background-color: $white;
  padding: 15px 0;
  transition: 0.4s;
  box-shadow: 0 0 3px rgba(0,0,0,0.15);

  &.sticky{
    animation: slideInDown 0.6s forwards;
    padding: 5px 0 15px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    @media #{$sm-device, $md-device} {
      padding: 10px 0;
    }

    .logo{
      &-dark{
        display: none;
      }
    }

    .main-menu{
      .submenu-nav{
        margin-top: 15px;

        &:before{
          height: 25px;
        }
      }
    }
  }
  &.transparent {
    &.hideTransparent {
      display: none;
    }

    &.sticky {
      display: block;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
      
      .main-menu{
        & > li {
          &.has-submenu {
            &:after {
              color: $brandColor;
            }
          }

          & > a {
            color: $brandColor;
          }
        }
      }

      .header-action{
        [class*='btn-']{
          color: $brandColor;
          margin-left: 20px;
        }
      }
    }
  }

  &.transparent:not(.sticky){
    background-color: transparent;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;

    .logo{
      &-light{
        display: none;
      }
    }

    .main-menu{
      & > li{

        &.has-submenu{
          &:after{
            color: #fff;
            font-weight: 600;
          }
        }

        & > a{
          color: #fff;
          font-weight: 400;
        }

        & > a:hover {
          color: $red;
        }
      }
    }

    .header-action{
      a,button{
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

// Index Action
.header-action{
  color: $white;
  font-size: 22px;

  @media #{$lg-device, $sm-device} {
    font-size: 20px;
  }

  a{
    color: $white;

    &.tel-no{
      @media #{$xs-device} {
        display: none;
      }
    }
  }

  [class*='btn-']{
    color: $brandColor;
    margin-left: 20px;
  }
}


//sub-header
.sub-header {
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGNDg1QTsiIGQ9Ik00NzkuMTgsOTEuODk3SDMyLjgyMUMxNC42OSw5MS44OTcsMCw3Ny4yMDcsMCw1OS4wNzdzMTQuNjktMzIuODIxLDMyLjgyMS0zMi44MjFINDc5LjE4DQoJCWMxOC4xMywwLDMyLjgyLDE0LjY5LDMyLjgyLDMyLjgyMVM0OTcuMzEsOTEuODk3LDQ3OS4xOCw5MS44OTd6Ii8+DQoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGNDg1QTsiIGQ9Ik0yOTUuMzg1LDI4OC44MjFIMzIuODIxQzE0LjY5LDI4OC44MjEsMCwyNzQuMTMsMCwyNTZzMTQuNjktMzIuODIxLDMyLjgyMS0zMi44MjFoMjYyLjU2NA0KCQljMTguMTMsMCwzMi44MjEsMTQuNjksMzIuODIxLDMyLjgyMVMzMTMuNTE1LDI4OC44MjEsMjk1LjM4NSwyODguODIxeiIvPg0KPC9nPg0KPHBhdGggc3R5bGU9ImZpbGw6I0ZGQkJDMDsiIGQ9Ik00NzkuMTgsMjg4LjgyMWgtNTIuNTEzYy0xOC4xMywwLTMyLjgyMS0xNC42OS0zMi44MjEtMzIuODIxczE0LjY5LTMyLjgyMSwzMi44MjEtMzIuODIxaDUyLjUxMw0KCWMxOC4xMywwLDMyLjgyLDE0LjY5LDMyLjgyLDMyLjgyMVM0OTcuMzEsMjg4LjgyMSw0NzkuMTgsMjg4LjgyMXoiLz4NCjxwYXRoIHN0eWxlPSJmaWxsOiNGRjQ4NUE7IiBkPSJNNDc5LjE4LDQ4NS43NDRIMzIuODIxQzE0LjY5LDQ4NS43NDQsMCw0NzEuMDUzLDAsNDUyLjkyM2MwLTE4LjEzLDE0LjY5LTMyLjgyMSwzMi44MjEtMzIuODIxDQoJSDQ3OS4xOGMxOC4xMywwLDMyLjgyLDE0LjY5LDMyLjgyLDMyLjgyMUM1MTIsNDcxLjA1Myw0OTcuMzEsNDg1Ljc0NCw0NzkuMTgsNDg1Ljc0NHoiLz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K");
  }
  
  nav {
    ul {
      width: 100%;

      li {
        margin: auto;
        padding-top: 20px;
        padding-bottom: 20px;

        a {
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.8);

          &:after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background-color: #F12535;
            transition: width .3s;
          }

          &:hover, &.active {
            color: #F12535;
            font-weight: bold;

            &:after {
                width: 100%;
                transition: width .3s;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 767px) {
      ul {
        display: -webkit-box;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        li {
          padding: 10px;

          a {
            font-size: 16px;
            line-height: 19px;
          }
        }
        
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}