/*===========================
4.1 - Main Navigation CSS
=============================*/

.main-menu {
  align-content: center;
  justify-content: flex-end;
  align-items: center;

  & > li {
    margin-right: 30px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    & > a, a:not([href]):not([class]) {
      color: $brandColor;
      display: block;
      line-height: 1;
      letter-spacing: 1.62px;
      text-transform: capitalize;
      position: relative;

      @media #{$lg-device} {
        font-size: 14px;
      }
    }

    a:hover, a:not([href]):not([class]):hover {
      color: $red;
    }

    // Sub Menu
    &.has-submenu {
      margin-right: 37px;
      padding-right: 10px;
      position: relative;

      // // Menu Item Arrows
      // &:after {
      //   color: #000;
      //   content: '\f107';
      //   font-size: 16px;
      //   line-height: 1;
      //   font-family: $fa-icon;
      //   position: absolute;
      //   right: -5px;
      //   top: 0;
      // }

      // Sub Menu
      .submenu-nav {
        background-color: $white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        // border-bottom: 2px solid $brandColor;
        position: absolute;
        left: -25px;
        top: 100%;
        transform: translateY(50px);
        transition: 0.4s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        width: max-content;
        margin-top: 20px;
        z-index: 99;

        @media #{$lg-device} {
          min-width: 220px;
          margin-top: 38px;
        }

        &:before {
          content: '';
          position: absolute;
          height: 40px;
          width: 100%;
          left: 0;
          bottom: 100%;
        }

        & > li {
          & > a {
            color: $paragraph;
            display: block;
            font-size: 15px;
            letter-spacing: inherit;
            text-transform: capitalize;
            padding: 10px 20px;
          }

          &:hover {
            & > a {
              background-color: $white;
              color: $red;
            }
          }
        }

        // Mega Menu
        &-mega {
          display: flex;
          margin-left: -300px;
          padding: 0;
          min-width: 750px;

          @media #{$lg-device} {
            width: 960px;
            margin-left: -245px;
          }

          .mega-menu-item {
            border-right: 1px solid $borderColor;
            padding: 30px;
            flex-basis: 33%;

            &:last-child {
              border-right: 0;
            }

            & > a {
              display: none;
            }

            ul {
              li {
                margin-bottom: 15px;

                &:last-child {
                  margin-bottom: 0;
                }

                a {
                  color: $paragraph;
                  font-size: 15px;
                  line-height: 1;
                  color: #000;
                  font-weight: 500;

                  img {
                    margin-right: 10px;
                  }

                  &:hover {
                    color: $red;
                  }
                }
              }
            }
          }
        }

        //Box-menu
        &-box {
          left: -200px;
          padding: 30px;
          padding-bottom: 10px;
          max-width: 900px;
          background-color: #F7F7F7;

          .title {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #000;
            margin-bottom: 20px;
          }

          .description {
            font-size: 14px;
            line-height: 18px;
            color: #000000;
          }

          .box-item {
            img {
              width: 100%;
              object-fit: cover;
              height: 120px;
              margin-bottom: 10px;
            }

            a {
              .title {
                text-align: center;
                font-size: 14px;
                word-break: break-word;
                text-transform: uppercase;
              }
              
              &:hover {
                .title {
                  color: $red;
                }
              }
            }
          }
        }
      }

      //Language Menu
      .lang-menu {
        @media #{$lg-device} {
            min-width: 200px;
            left: -100px;
        }
      }

      // After Hover Sub Menu
      &:hover {
        & > .submenu-nav {
          transform: none;
          opacity: 1;
          visibility: visible;
          pointer-events: visible;
        }
      }
    }
  }
}

/* Responsive Mobile Menu */
.mobile-nav-container {
  display: none;
  
  @media screen and (max-width: 1199px) {
    display: block;
  }
}
.res-mobile-menu {
  background-color: $brandColor;
  display: block !important;
  padding: 20px 30px;

  .mobile-nav{
    li {
      // border-bottom: 1px solid rgba(255, 255, 255, .2);
      position: relative;
      list-style: none;
      padding: 5px 0;

      &:last-child {
        border-bottom: 0;
      }

      a {
        color: $white;
        font-size: 16px;
        padding: 15px 0;
        margin: 0;
        text-transform: capitalize;
        position: relative;

        &:hover {
          color: $red;
          background-color: transparent;
        }
      }

      &.has-submenu {
        // &:after {
        //   color: $white;
        //   content: '\f107';
        //   font-size: 16px;
        //   line-height: 1;
        //   font-family: $fa-icon;
        //   position: absolute;
        //   right: -5px;
        //   top: 10px;
        // }

        & ul, .submenu-nav-box {
          display: none;
          visibility: hidden;
          opacity: 0;
        }

        &:hover ul, ul li:hover > ul, :focus-within > ul, ul:hover {
          transition: 0.4s;
          display: block;
          visibility: visible;
          opacity: 1;
        }

        &:hover .submenu-nav-box, :focus-within > .submenu-nav-box, .submenu-nav-box:hover, .submenu-nav-box.submenu-open {
          transition: 0.4s;
          display: block;
          visibility: visible;
          opacity: 1;
        }

        .submenu-nav {
          &.submenu-open {
            transition: 0.4s;
            display: block;
            visibility: visible;
            opacity: 1;
          }

          &.submenu-close {
            transition: 0.4s;
            display: none;
            visibility: hidden;
            opacity: 0;
          }
          
          &-box, &-mega {
            &.submenu-open {
              transition: 0.4s;
              display: block;
              visibility: visible;
              opacity: 1;
            }

            &.submenu-close {
              transition: 0.4s;
              display: none;
              visibility: hidden;
              opacity: 0;
            }
          }
        }
      }

      .submenu-nav-box {
        .common-info {
          display: none;
        }

        .box-item {
          img {
            display: none;
          }

          a {
            .title {
              word-break: break-word;
              text-transform: capitalize;
            }

            &:hover {
              .title {
                color: $red;
              }
            }
          }
        }
      }

      // img {
      //   display: none;
      // }

      ul {
        // border-top: 1px solid rgba(255, 255, 255, .2);
        margin: 0;
        padding-left: 10px;

        li {

          &.mega-menu-item{
            & > a{
              font-weight: 600;
            }

            ul li a img {
              margin-right: 10px;
            }
          }

          a {
            font-size: 13px;
            color: rgba($white, 0.7);
          }
        }
      }
    }
  }
}