//== Typography Variable
$baseFont: "Muli",sans-serif;;
$baseFontSize: 15px;
$mdi-icon: 'Material Design Icons';
$fa-icon: 'FontAwesome';

//== Site Typography Colors
$paragraph: #595959;
$headingColor: #151515;
$ashColor: #aaaaaa;
$borderColor: #eee;
$hvrColor: #182141;

//== Site Brand Color
$brandColor: #1d1d20;
$brandColorDark: darken($brandColor, 15%);
$brandColorLight: lighten($brandColor, 15%);

//== Alternative Color
$white: #FFFFFF;
$offWhite: #f8f9fa;
$black: #000000;
$blackSoft: #222222;
$navy: #3b5998;
$pink: #FF69B4;
$violet: #9933cc;
$olive: #559137;
$green: #008000;
$red: #ae0a16;
$orange: #f5740a;
$yellow: #fbbf00;

// Color system

$white:    #fff;
$gray-100: #f8f9ff;
$gray-200: #f4f5fd;
$gray-300: #eeeff8;
$gray-400: #e6e7f1;
$gray-500: #dfe0ea;
$gray-600: #d1d2db;
$gray-700: #a6a6b9;
$gray-800: #7a7b97;
$gray-900: #3b3e66;
$black: #070919;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
                (
                        "100": $gray-200,
                        "200": $gray-300,
                        "300": $gray-400,
                        "400": $gray-500,
                        "500": $gray-600,
                        "600": $gray-700,
                        "700": $gray-800,
                        "800": $gray-900,
                        "900": $black
                ),
                $grays
);

$first:   #b60b00;
$second:  $black;
$blue:    #4191ff;
$indigo:  #7420ff;
$purple:  #793de6;
$pink:    #fc26a4;
$red:     #f83245;
$orange: #f4772e;
$yellow:  #ffc926;
$green:   #4FB779;
$teal:    #18e1a5;
$cyan: #11c5db;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
                (
                        "blue":       $blue,
                        "indigo":     $indigo,
                        "purple":     $purple,
                        "pink":       $pink,
                        "red":        $red,
                        "orange":     $orange,
                        "yellow":     $yellow,
                        "green":      $green,
                        "teal":       $teal,
                        "cyan":       $cyan,
                        "white":      $white,
                        "gray":       $gray-600,
                        "gray-dark":  $gray-800
                ),
                $colors
);

$primary:       $first;
$secondary:     $gray-100;
$success:       $green;
$info:          $cyan;
$warning:       $orange;
$danger:        $red;
$light:         $gray-200;
$dark:          $gray-800;
$link:          $blue;

$primary-neutral:       lighten(#f2c7c4, 6%);
$secondary-neutral:     lighten(#f5f6f7, 6%);
$success-neutral:       lighten(#ccf4dc, 6%);
$info-neutral:          lighten(#ccf7fa, 6%);
$warning-neutral:       lighten(#f8e5cb, 6%);
$danger-neutral:        lighten(#ffd6db, 6%);
$light-neutral:         lighten(#fdfdff, 6%);
$dark-neutral:          #f9f9f9;
$first-neutral:         lighten(#cce9fe, 6%);
$second-neutral:        lighten(#d0d2da, 6%);
$cyan-neutral:          lighten(#c8e8ec, 6%);

$gradient-primary: linear-gradient(315deg,#eb4511,#b60b00 74%);

$facebook:      #3b5999;
$discord:       #7189da;
$twitter:       #1da1f2;
$google:        #dd4b39;
$instagram:     #e4405f;
$pinterest:     #bd081c;
$youtube:       #cd201f;
$slack:         #3aaf85;
$dribbble:      #ea4c89;
$github:        #222222;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "gray":       $gray-100,
        "dark":       $dark,
        "first":      $blue,
        "second":     $second,
        "cyan":       $cyan
    ),
    $theme-colors
);

$theme-colors-neutral: ();
$theme-colors-neutral: map-merge(
    (
        "primary":    $primary-neutral,
        "secondary":  $secondary-neutral,
        "success":    $success-neutral,
        "info":       $info-neutral,
        "warning":    $warning-neutral,
        "danger":     $danger-neutral,
        "dark":       $dark-neutral,
        "first":      $first-neutral,
        "second":     $second-neutral,
        "cyan":       $cyan-neutral
    ),
    $theme-colors-neutral
);

$brand-colors: ();
$brand-colors: map-merge(
                (
                        "facebook":      $facebook,
"discord":      $discord,
                        "twitter":       $twitter,
                        "google":        $google,
                        "instagram":     $instagram,
                        "pinterest":     $pinterest,
                        "youtube":       $youtube,
                        "slack":         $slack,
                        "dribbble":      $dribbble,
                        "github":        $github,
                ),
                $brand-colors
);

//== Responsive Media Query Variables
$xlmax-device: 'only screen and (min-width: 1200px) and (max-width: 1599.98px)';
$xl-device: 'only screen and (min-width: 1200px)';
$lgmax-device: 'only screen and (min-width: 992px)';
$lg-device: 'only screen and (min-width: 992px) and (max-width: 1199.98px)';
$md-device: 'only screen and (min-width: 768px) and (max-width: 991.98px)';
$mdmax-device: 'only screen and (max-width: 991.98px)';
$mdmin-device: 'only screen and (min-width: 768px)';
$sm-device: 'only screen and (max-width: 767.98px)';
$xs-device: 'only screen and (max-width: 575.98px)';
$xxs-device: 'only screen and (max-width: 479.98px)';

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-extrabold:       800;
$font-weight-black:           900;
$font-weight-bolder:          bolder;

$transition-base:             all .2s ease-in-out;

$border-radius:               .65rem;
$border-radius-lg:            .75rem;
$border-radius-sm:            .29rem;
$border-radius-xs:            .2rem;