/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

/* Rounded tabs */

@media (min-width: 576px) {
  .rounded-nav {
    border-radius: 50rem !important;
  }
}

@media (min-width: 576px) {
  .rounded-nav .nav-link {
    border-radius: 50rem !important;
  }
}

/* With arrow tabs */
.with-arrow {
  width: 100%;
  justify-content: center;
}

.with-arrow .nav-link.active {
  position: relative;
}

.with-arrow .nav-link.active::after {
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid $red;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

/* lined tabs */

.lined .nav-link {
  font-size: 17px;
  line-height: 26px;
  font-weight: 800;
  border: none;
  border-bottom: 3px solid transparent;
}

.lined .nav-link:hover {
  border: none;
  border-bottom: 3px solid transparent;
}

.lined .nav-link.active {
  background: none;
  color: $red;
  border-color: $red;
}

.nav-link {
  color: $brandColor;

  &.disbled {
    display: none;
  }
}

.nav-link:hover {
  color: $red;
}

/*
  *
  * ==========================================
  * FOR DEMO PURPOSE
  * ==========================================
  *
  */

.nav-pills .nav-link {
  color: #555;
}
.text-uppercase {
  letter-spacing: 0.1em;
}

.tab-widden {
  @media #{$sm-device} {
    display: none;
  }
}

.tab-dropdown {
  display: none;

  @media #{$sm-device} {
    display: block;
  }
}
