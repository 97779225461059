.preloader {
  width: 100%;
  height: 100%;
  background-color: rgba(64, 64, 64, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.clear-loading {
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -75px;
  margin-left: -75px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.loading-effect-2 {
  width: 150px;
  height: 150px;
}

.loading-effect-2 > span,
.loading-effect-2 > span:before,
.loading-effect-2 > span:after {
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading-effect-2 > span {
  border: 2px solid #ae0a16;
}

.loading-effect-2 > span:before {
  border: 2px solid #1d1d20;
}

.loading-effect-2 > span:after {
  border: 2px solid #ae0a16;
}

.loading-effect-2 > span {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-left-color: transparent;
  -webkit-animation: effect-2 2s infinite linear;
  -moz-animation: effect-2 2s infinite linear;
  -ms-animation: effect-2 2s infinite linear;
  -o-animation: effect-2 2s infinite linear;
  animation: effect-2 2s infinite linear;
}

.loading-effect-2 > span:before {
  width: 75%;
  height: 75%;
  border-right-color: transparent;
}

.loading-effect-2 > span:after {
  width: 50%;
  height: 50%;
  border-bottom-color: transparent;
}

@-webkit-keyframes effect-2 {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes effect-2 {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// style - 1
.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;

    &:nth-child(1) {
      animation-delay: 0s;
      top: 37px;
      left: 66px;
    }

    &:nth-child(2) {
      animation-delay: -0.1s;
      top: 22px;
      left: 62px;
    }

    &:nth-child(3) {
      animation-delay: -0.2s;
      top: 11px;
      left: 52px;
    }

    &:nth-child(4) {
      animation-delay: -0.3s;
      top: 7px;
      left: 37px;
    }

    &:nth-child(5) {
      animation-delay: -0.4s;
      top: 11px;
      left: 22px;
    }

    &:nth-child(6) {
      animation-delay: -0.5s;
      top: 22px;
      left: 11px;
    }

    &:nth-child(7) {
      animation-delay: -0.6s;
      top: 37px;
      left: 7px;
    }

    &:nth-child(8) {
      animation-delay: -0.7s;
      top: 52px;
      left: 11px;
    }

    &:nth-child(9) {
      animation-delay: -0.8s;
      top: 62px;
      left: 22px;
    }

    &:nth-child(10) {
      animation-delay: -0.9s;
      top: 66px;
      left: 37px;
    }

    &:nth-child(11) {
      animation-delay: -1s;
      top: 62px;
      left: 52px;
    }

    &:nth-child(12) {
      animation-delay: -1.1s;
      top: 52px;
      left: 62px;
    }
  }
}

@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}
