/*===========================
17. Page Index Style CSS
=============================*/

.page-header-area {
  position: relative;

  .page-header-content-inner {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    position: relative;

    @media #{$md-device} {
      height: 400px;
    }

    @media #{$sm-device} {
      height: 400px;
    }

    h2 {
      color: $white;
      // text-transform: uppercase;
      width: 100%;
      max-width: 900px;
      margin: 0;
      display: inline-block;
      color: #fff;
      text-align: inherit;
      font-weight: 700;
      line-height: 1.2;
      -webkit-transition-delay: .3s;
      -o-transition-delay: .3s;
      transition-delay: .3s;
      @include font-size(40px);

      @media #{$xs-device} {
        font-size: 24px;
      }
    }

    .vc_separator {
      margin-top: 2rem;

      .vc_line {
        width: 100px;
      }
    }

    .category-box {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: fit-content;
      margin-top: 20px;
      border: 1px solid $white;
      border-radius: 20px;
      padding: 8px 16px;
    }

    p {
      @media #{$xl-device} {
        max-width: 70%;
        margin: auto;
      }
    }
  }
}

.page-header-area::before {
  content: '';
  display: table;
  clear: both;
}

.page-header-area:after {
  content: '';
  background: rgba(31,31,32,.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.breadcrumb-wrap{
  margin-top: 25px;

  @media #{$sm-device} {
    margin-top: 15px;
  }

  .breadcrumb{
    background-color: transparent;
    border-radius: 0;
    justify-content: center;
    padding: 0;
    margin: 0;

    li{

      &:last-child{
        a{
          &:after{
            display: none;
          }
        }
      }

      a{
        color: $white;
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
        position: relative;

        &:after{
          content: '-';
          margin: 0 20px;
          pointer-events: none;

          @media #{$sm-device} {
            margin: 0 10px;
          }
        }

        &.current{
          pointer-events: none;
          opacity: 0.8;
        }
      }
    }
  }
}